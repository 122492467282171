import './SubCategories.css'
import { connect } from 'react-redux'
import { useState, useEffect } from 'react'
import { Search } from '../../../components/inputs'
import { openSnackbar } from '../../../store/actions'
import { useAxios, useAxiosAuth } from '../../../hooks'
import { validateArray } from '../../../utils/Validation'
import { LoadCircle } from '../../../components/animation'
import { ListCourses } from '../../../containers/catalogue'
import { H2 } from '../../../components/text'

const SubCategories = (props) => {
  const {
    location,
    match: { params },
  } = props
  const [url, seturl] = useState('')
  const [shopping, setshopping] = useState([])
  const searchUrl = new URLSearchParams(location.search)
  const [category, setcategory] = useState('' || searchUrl.get('category'))

  const { response: shopp, reload } = useAxiosAuth({
    method: 'GET',
    url: window.localStorage.getItem('id') ? '/Shoppingcart' : '',
  })

  function reloadShopping() {
    reload()
    setshopping(getShopping())
  }

  useEffect(() => {
    setshopping(getShopping())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopp])

  function getShopping() {
    if (window.localStorage.getItem('id')) {
      return shopp ? shopp : []
    } else {
      const storageShop = window.localStorage.getItem('shopping')
        ? JSON.parse(window.localStorage.getItem('shopping'))
        : [] || []
      return storageShop
    }
  }

  const { response, loading } = useAxios({
    method: 'GET',
    url: `/category/${params.id}/children`,
  })

  return loading ? (
    <LoadCircle />
  ) : (
    <>
      <H2 margin="0">PASTILLAS EDUCATIVAS</H2>
      <div className="SubCategories__list">
        <HeaderSearch
          {...props}
          seturl={seturl}
          response={response}
          category={category}
          setcategory={setcategory}
        />
      </div>
      {category ? (
        <ListCourses
          category={category}
          shopping={shopping}
          reloadShopping={reloadShopping}
          url={url}
          {...props}
        />
      ) : (
        <ListCourses
          category={params.id}
          shopping={shopping}
          reloadShopping={reloadShopping}
          url={url}
          {...props}
        />
      )}
    </>
  )
}

const HeaderSearch = (props) => {
  const {
    seturl,
    history,
    response,
    category,
    setcategory,
    match: { params },
  } = props

  function handleSelect(setFunction, e) {
    const { value } = e.target
    history.push(`/catalogue/${params.url}/${params.id}?category=${value}`)
    setFunction(value)
  }

  return (
    <>
      {validateArray(response) && (
        <select
          className="SubCategories__select"
          onChange={(e) => handleSelect(setcategory, e)}
          value={category}
        >
          <option value="">Todas las categorias</option>
          {response.map((subCategory) => (
            <option key={subCategory.id} value={subCategory.id}>
              {subCategory.name}
            </option>
          ))}
        </select>
      )}
      <div className="SubCategories__list">
        <CategoryFilters seturl={seturl} />
      </div>
    </>
  )
}

const CategoryFilters = (props) => {
  const { seturl } = props
  const [search, setsearch] = useState({
    field: 'name',
    search: '',
  })
  function onChange(e) {
    const { name, value } = e.target
    setsearch((a) => ({ ...a, [name]: value }))
  }
  function onClick() {
    seturl(
      `${search?.search
        ? '&ForQuery=' + search.search + '&TypeQuery=' + search.field
        : ''
      }`,
    )
  }
  return (
    <Search
      options={[{ key: 'name', name: 'Nombre del curso' }]}
      onChange={onChange}
      onClick={onClick}
    />
  )
}

const mapStateToProps = (state) => {
  return {
    membership: state.membership,
  }
}
export default connect(mapStateToProps, { openSnackbar })(SubCategories)
