import './style/LogoCapacita.css'
import { ImgDefault } from '../image'
import { partelogo1, partelogo2 } from '../../utils/Constant'
const LogoCapacita = () => {
  const currentSize = { width: 150 }
  return (
    <div className="LogoCapacita">
      {/* <ImgDefault
        className="LogoCapacita__fondo"
        url={partelogo1}
        currentSize={currentSize}
        alt="Logo parte 1"
      />
      <ImgDefault
        className="LogoCapacita__main"
        currentSize={currentSize}
        url={partelogo2}
        alt="Logo parte 2"
      /> */}
    </div>
  )
}

export default LogoCapacita
