import { Formik, Form } from 'formik'
import { NormalButton } from '../../components/buttons'
import { FormikControl } from '../../components/input'
import { Loading } from '../../components/animation'
import { H2 } from '../../components/text'
import { useTranslation } from 'react-i18next'
import './style/EditPerfil.css'
import React, { useState } from 'react'
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input'
import { connect } from 'react-redux'
import { updateUser, openSnackbar, changeInfo } from '../../store/actions'
import * as Yup from 'yup'
import { updateUserService } from '../../services/action/UsuarioAction'
import { validateVar } from '../../utils/Validation'
import { convertDate, getAge } from '../../utils/ConvertDate'
const EditPerfil = (props) => {
  const [load, setload] = useState(true)
  const { updateUser, openSnackbar, changeInfo } = props
  const us = props.sesionUser.user
  const { t } = useTranslation('editPerfil')
  const [phone, setPhone] = useState(undefined)
  const dropdownOptions = [
    { key: 'Seleccionar genero', value: '' },
    { key: t('female'), value: 'female' },
    { key: t('male'), value: 'male' },
  ]
  const initialValues = {
    Email: us.email,
    Name: validateVar(us.firstName),
    LastName: validateVar(us.lastName),
    Gender: validateVar(us.gender),
    // DateOfBirth: "15/01/2023",
    DateOfBirth: Date.parse("15/01/2023"),
    Nickname: validateVar(us.nickname),
    CountryAddressId: '1',
    CityAddressId: '1',
    Address1: validateVar(us.Address),
  }

  const validationSchema = Yup.object({
    Name: Yup.string().required('required'),
    LastName: Yup.string().required('required'),
    Gender: Yup.string().required('required'),
    DateOfBirth: Yup.date().required('Required').nullable(),
    Nickname: Yup.string().required('required'),
    CountryAddressId: Yup.string().required('required'),
    CityAddressId: Yup.string().required('required'),
    Address1: Yup.string().required('required'),
    // Address2: Yup.string().required("required"),
    // PostalCode: Yup.string(),
  })
  function save(res) {
    setload(true)
    if (res.status === 200) {
      const user = JSON.parse(res.config.data)
      openSnackbar('Actulizacion Exitosa', true, true)
      updateUser({
        ...us,
        firstName: user.Name,
        lastName: user.LastName,
        gender: user.Gender,
        age: getAge(user.DateOfBirth),
        nickname: user.Nickname,
        Address: user.Address1,
        // Address2: user.Address2,
        // postalCode: user.PostalCode,
        Number: phone,
      })
    } else {
      openSnackbar('Error al guardar', true)
    }
  }
  const onSubmit = async (values) => {
    try {
      setload(false)
      if (phone !== undefined) {
        const codeNumber = parsePhoneNumber(phone)
        const data = {
          ...values,
          Country: codeNumber.country,
          CountryCode: codeNumber.countryCallingCode,
          NationalNumber: codeNumber.nationalNumber,
          Number: codeNumber.number,
        }
        const id = us.id
        await updateUserService(id, data).then((response) => {
          console.log(' response', response)
          save(response)
        })
      } else {
        setload(true)
        openSnackbar('Datos del telefono incorrecto', true)
      }
    } catch (e) {
      setload(true)
      console.log(e)
    }
  }
  return (
    <div className="EditPerfil">
      <H2 textAlign="center" color="var(--gray)">
        Editar informacion
      </H2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <FormikControl
                control="input"
                type="text"
                label={t('Nickname')}
                name="Nickname"
              />
              <FormikControl
                control="input"
                type="text"
                label={t('epName')}
                name="Name"
              />
              <FormikControl
                control="input"
                type="text"
                label={t('epLastN')}
                name="LastName"
              />
              <label className="EditPerfil__label">Número de celular</label>
              <PhoneInput
                placeholder="Ingresa tu numero"
                value={phone}
                onChange={setPhone}
              />
              <FormikControl
                control="select"
                label="Genero"
                name="Gender"
                options={dropdownOptions}
              />
              <FormikControl
                control="input"
                type="date"
                label={t('epDate')}
                name="DateOfBirth"
              />
              <div>
                {console.log(formik.values.DateOfBirth)}
              </div>
              <FormikControl
                control="input"
                type="text"
                label={t('epDirection')}
                name="Address1"
              />
              {/* <FormikControl
                control="input"
                type="text"
                label="Departamento"
                name="Address2"
              /> */}
              {/* <FormikControl
                control="input"
                type="text"
                label={t("epCodeP")}
                name="PostalCode"
              /> */}
              {load ? (
                <div className="ChangePassword__btn">
                  <NormalButton type="submit" disabled={!formik.isValid}>
                    {t('epSave')}
                  </NormalButton>
                  <button
                    type="reset"
                    onClick={() => changeInfo(<></>)}
                    className="ChangePassword__btnC"
                  >
                    {t('epCancel')}
                  </button>
                </div>
              ) : (
                <Loading />
              )}
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  }
}
const mapDispatchToProps = {
  updateUser,
  openSnackbar,
  changeInfo,
}
export default connect(mapStateToProps, mapDispatchToProps)(EditPerfil)
