import { Template } from './components/layout'
import './assets/style/globalStyle.css'
import Theme from './components/theme'
import { I18nextProvider } from 'react-i18next'
import i18n from './translate/i18n'
import { useEffect } from 'react'
import { getAction } from './services/action/ActionAuthorization'
import { useActionParam } from './hooks'
import { startSession, closeSession } from './store/actions'
import { connect } from 'react-redux'
import TagManager from 'react-gtm-module'
import { validateStatus } from './utils/Validation'
import { LoadingScreen } from './components/common'
import { useState } from 'react'

const tagManagerArgs = {
  gtmId: 'GTM-NWXKB3C',
}
TagManager.initialize(tagManagerArgs)

const App = ({ startSession, sesionUser, closeSession }) => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const idLs = window.localStorage.getItem('id')
  const [credentials, setCredentials] = useState({
    id: urlParams.get('iduser'),
    token: urlParams.get('token'),
    status: false,
  })
  const { dataRequest, statusRequest, reload } = useActionParam({
    action: getAction,
    param: idLs ? `/users/${idLs}/detailed` : '',
  })

  useEffect(() => {
    if (statusRequest && validateStatus(statusRequest)) {
      const { institutions, courses, userToReturn, father } = dataRequest
      startSession(
        { ...userToReturn, father: father },
        courses,
        institutions,
        true,
      )
    }
  }, [dataRequest, statusRequest, startSession])

  useEffect(() => {
    if (
      statusRequest !== 200 &&
      statusRequest !== null &&
      credentials.token === null
    ) {
      closeSession()
      localStorage.removeItem('token_seguridad')
      localStorage.removeItem('id')
    }
  }, [statusRequest, closeSession])

  useEffect(() => {
    if (credentials.id !== null && credentials.id !== null) {
      window.localStorage.setItem('id', credentials.id)
      window.localStorage.setItem('token_seguridad', credentials.token)
      reload()
      setCredentials((a) => ({ ...a, status: true }))
    }
  }, [])

  if (
    (credentials.id === null && credentials.token === null) ||
    credentials.status === true
  ) {
    if (
      (!sesionUser.authenticate && !idLs) ||
      (idLs && sesionUser.authenticate)
    ) {
      return (
        <I18nextProvider i18n={i18n}>
          <Theme>
            <Template />
          </Theme>
        </I18nextProvider>
      )
    } else {
      return <LoadingScreen />
    }
  } else {
    return <LoadingScreen />
  }
}
const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  }
}
export default connect(mapStateToProps, {
  startSession,
  closeSession,
})(App)
