import './style/Header.css'
import { Link, withRouter } from 'react-router-dom'
import { Sidebar } from '../navigation'
import { ButtonModalS, NormalLink } from '../buttons'
import { H3 } from '../text'
const Header = (props) => {
  const { headerTitle, location, membership, sesionUser, history } = props

  function clickHandler(e, element) {
    history.push('/')
    if (location.pathname === '/') {
      e.preventDefault()
      document
        .getElementById(element)
        .scrollIntoView({ block: 'start', behavior: 'smooth' })
    }
  }
  return (
    <>
      <div className="Header">
        <Sidebar />
        <Link to="/catalogue/short-courses/4">{headerTitle}</Link>
        <ButtonModalS {...props} />
      </div>
      {/*{(location.pathname.includes('/catalogue/short-courses') &&
        membership?.status !== 'completed') ||
        (location.pathname.includes('/graduates/') &&
          location.pathname.includes('/course') &&
          membership?.status !== 'completed') ? (
        <HeaderBanner />
      ) : null} */}
    </>
  )
}
// const HeaderBanner = () => {
//   return (
//     <Link style={{ textDecoration: 'none' }} to="/payment">
//       <div className="HeaderBanner">
//         <div className="HeaderBanner__main">
//           <H3 margin="0" fontSize="5rem" color="var(--white)" fontWeight="500">
//             14.<span style={{ fontSize: '2.5rem' }}>99</span> SUS
//           </H3>
//           <div className="HeaderBanner__text">
//             <H3 margin="0" color="var(--white)" fontWeight="700">
//               Plan Mensual
//             </H3>
//             <H3 margin="0" color="var(--white)" fontWeight="500">
//               Adquiere una suscripción en nuestra plataforma
//             </H3>
//           </div>
//         </div>
//         <NormalLink to="/payment" background="var(--white)">
//           Ver planes
//         </NormalLink>
//       </div>
//     </Link>
//   )
// }
export default withRouter(Header)
