import { useEffect } from 'react'
import { connect } from 'react-redux'
import { H2 } from '../../../../../components/text'
import { useAxiosAuth } from '../../../../../hooks'
import { urlAvatar } from '../../../../../utils/Constant'
import { ColoredBoxes } from '../../../../../components/boxes'
import { LoadCircle } from '../../../../../components/animation'
import { AnswersUserAside } from '../../../../../containers/programs'

import {
  changeLayout,
  changeInfo,
  openSnackbar,
} from '../../../../../store/actions'
import { TableOptions } from '../../../../../components/table'
const QuizRatings = (props) => {
  const { match, changeLayout, changeInfo, openSnackbar } = props
  const { response, loading } = useAxiosAuth({
    method: 'GET',
    url: `/lesson/${match.params.qid}/quizzesAccountant`,
  })
  useEffect(() => {
    changeLayout()
  }, [changeLayout])
  const header = [
    { key: 'photoUrl', name: 'Avatar', type: 'img' },
    { key: 'userName', name: 'Nombres' },
    { key: 'userLastName', name: 'Apellidos' },
    {
      key: 'status',
      name: 'Estado',
      type: 'textColor',
      color: {
        Inconcluso: '#c93f3f75',
        Concluido: '#46958575',
      },
    },
    { key: 'score', name: 'Nota' },
  ]
  const headerSearch = [
    { key: 'userName', name: 'Nombres ' },
    { key: 'userLastName', name: 'Apellidos' },
  ]
  function preConfig(list) {
    list.forEach((u) => {
      if (u.status === 'available' || u.status === 'Inconcluso') {
        u.status = 'Inconcluso'
      } else {
        u.status = 'Concluido'
      }
      if (!u.photoUrl) {
        u.photoUrl = urlAvatar
      }
    })
    return list
  }
  function handleInfo(user) {
    changeInfo(
      <AnswersUserAside
        user={user}
        qid={match.params.qid}
        changeInfo={changeInfo}
        openSnackbar={openSnackbar}
      />,
    )
  }
  return loading ? (
    <LoadCircle />
  ) : (
    <>
      <ColoredBoxes
        boxes={[
          {
            title: response.finished,
            subTitle: 'Presentados',
            status: 'green',
          },
          {
            title: response.approved,
            subTitle: 'Aprobados',
            status: 'green',
          },
          {
            title: response.failed,
            subTitle: 'Reprobados',
            status: 'red',
          },
        ]}
      />
      <br />
      <H2>Notas de los estudiantes</H2>
      {/* <TableDefault
        handleInfo={handleInfo}
        header={header}
        main={preConfig(response)}
      /> */}

      <TableOptions
        param={`/lesson/${match.params.qid}/quizzes`}
        header={header}
        /* param={`/lesson/${lid}/homeworksAccountant`} */
        /*listStatus={listStatus} */
        /* listOrder={listOrder} */
        choice={true}
        /*handleReload={reload}*/
        handleInfo={handleInfo}
        headerSearch={headerSearch}
        preConfig={preConfig}
      />
    </>
  )
}
export default connect(null, { changeLayout, changeInfo, openSnackbar })(
  QuizRatings,
)
