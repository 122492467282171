import { ModalRegister } from '../../containers/shopping/ShoppingAside'
import { changeLayout, openSnackbar } from '../../store/actions'
import { connect } from 'react-redux'
import { useEffect } from 'react'
const OnlyPayment = (props) => {
  const { changeLayout } = props

 /*  function onCancel() {
    console.log('onCancel')
  }
 */
  useEffect(() => {
    changeLayout('FullScreen')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div style={{ maxWidth: '800px', margin: '0 auto' }}>
      <ModalRegister
        {...props}
        dataShopping={{ total: 35, currency: 'USD' }}
        urlPost="/membership"
        orderResult="only-payment-result"
        dataPayment={{ Plan: 'Monthly' }}
        disabledPaymentType={true}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  }
}
export default connect(mapStateToProps, {
  changeLayout,
  openSnackbar,
})(OnlyPayment)
