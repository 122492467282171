import { useEffect } from 'react'
import { changeLayout } from '../../store/actions'
import { LogoCapacita } from '../../components/animation'
import { connect } from 'react-redux'
import error404 from '../../assets/img/error-404.png'
import { Link } from 'react-router-dom'
import './404.css'

const Page404 = (props) => {
  const { changeLayout, location } = props
  useEffect(() => {
    changeLayout('FullScreen')
  }, [changeLayout])
  if (location.pathname === '/') {
    return <LogoCapacita />
  } else {
    return (
      <div className="page404__home">
        <div className="page404__home-img">
          <img src={error404} alt="text-error" />
        </div>
        <div className="page404__home-text">
          Lo sentimos, no hemos podido encontrar esta página,
          <br /> parece que nunca existió... o quizá exista en un futuro.
          <br />
          Prueba nuevamente o pulsa el siguiente botón.
          <br />
          <br />
          <Link to="/">Volver al Inicio</Link>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = {
  changeLayout,
}
export default connect(null, mapDispatchToProps)(Page404)
