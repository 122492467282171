import { useState } from 'react'
import { H2, H4 } from '../text'
import './style/GraduateCard.css'
import { ToolTip } from '../boxes'
import { useSize } from '../../hooks'
import { withRouter, Link } from 'react-router-dom'
import { UseOnSubmit } from '../../hooks/useOnSubmit'
import { processUrlImage } from '../../utils/ProcessData'
const GraduateCard = (props) => {
  const {
    course,
    url,
    color,
    shopping = [],
    openSnackbar,
    reloadShopping,
    shopBoolean,
    href = '',
    type,
  } = props
  const [target, currentSize] = useSize()
  const filterShopping = shopping.filter(
    (f) => f.programManagerId === course.programManagerId,
  )
  const [shop, setshop] = useState(
    // shopping.filter((f) => f.programManagerId === course.programManagerId)
    filterShopping.length > 0,
  )
  async function handleShopping(course) {
    if (window.localStorage.getItem('id')) {
      await UseOnSubmit({
        axiosParams: {
          method: 'POST',
          url: 'Shoppingcart',
          data: {
            ProgramManagerId: course.programManagerId,
          },
        },
        openSnackbar: openSnackbar,
        textSuccess: 'Se adiciono al carrito con exito',
        textWrong: 'No se pudo adicionar',
        functionSuccess: () => setshop(!shop),
      })
    } else {
      window.localStorage.setItem(
        'shopping',
        JSON.stringify([
          ...shopping,
          { programManagerId: course.programManagerId, name: course.name },
        ]),
      )
      openSnackbar('Se adiciono al carrito con exito', true, true)
      setshop(!shop)
    }
    reloadShopping()
  }
  async function handleRemove() {
    if (window.localStorage.getItem('id')) {
      await UseOnSubmit({
        axiosParams: {
          method: 'DELETE',
          url: `/Shoppingcart/${filterShopping[0].id}`,
        },
        openSnackbar: openSnackbar,
        textSuccess: 'Se elimino del carrito',
        textWrong: 'No se pudo eliminar',
        functionSuccess: () => setshop(!shop),
      })
    } else {
      const shoppingList = shopping.filter(
        (f) => f.programManagerId !== course.programManagerId,
      )
      window.localStorage.setItem('shopping', JSON.stringify([...shoppingList]))
      openSnackbar('Se elimino del carrito', true, true)
      setshop(!shop)
    }
    reloadShopping()
  }
  function MainCard() {
    return (
      <>
        <div
          style={{
            background: `transparent linear-gradient(90deg, ${color} 0%, ${color} 40%, ${color}00 100%)`,
          }}
          className="GraduateCard__background"
        ></div>
        <div className="GraduateCard__text">
          <H2 color="var(--white)" margin="0">
            {course.name}
          </H2>
        </div>
      </>
    )
  }
  if (course) {
    return (
      <div className="GraduateCard__container">
        {shopBoolean && (
          <ToolTip
            content={shop ? 'Eliminar del carrito' : 'Añadir al carrito'}
          >
            <div
              onClick={() =>
                shop ? handleRemove(course, shop) : handleShopping(course)
              }
              style={{ background: color }}
              className="GraduateCard__cartPlus"
            >
              <H4>{shop ? 'Eliminar del carrito' : 'Añadir al carrito'}</H4>
              <i
                className={`fas fa-${shop ? 'cart-arrow-down' : 'cart-plus'}`}
              ></i>
            </div>
          </ToolTip>
        )}
        {href ? (
          <a
            style={{
              backgroundImage: `url(${
                course.photoUrl
                  ? course.photoUrl
                  : 'https://res.cloudinary.com/dzxmzxvbr/image/upload/v1607986593/diplomados/fotos/06GestionTributaria_hadppb.png'
              })`,
            }}
            to={`/${url}${
              course.programManagerId ? '/' + course.programManagerId : ''
            }`}
            className="GraduateCard"
            href={href}
            target="_blank"
            rel="noopener noreferrer"
          >
            <MainCard />
          </a>
        ) : (
          <Link
            style={{
              backgroundImage: `url(${processUrlImage(
                course.photoUrl
                  ? course.photoUrl
                  : 'https://res.cloudinary.com/dzxmzxvbr/image/upload/v1607986593/diplomados/fotos/06GestionTributaria_hadppb.png',
                currentSize.width,
              )})`,
            }}
            to={`/${url}${
              course.programManagerId ? '/' + course.programManagerId : ''
            }${type ? '/' + type : ''}`}
            className="GraduateCard"
            ref={target}
          >
            <MainCard />
          </Link>
        )}
      </div>
    )
  }
  return null
}

export default withRouter(GraduateCard)
