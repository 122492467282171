import HttpClient from '../HttpClient'
export const postAction = (url, body) => {
  return new Promise((resolve, eject) => {
    HttpClient.post(url, body)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        console.log(error.response)
        resolve(error.response)
      })
  })
}
export const postEmptyAction = (url, body) => {
  return new Promise((resolve, eject) => {
    HttpClient.postEmpty(url)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        console.log(error.response)
        resolve(error.response)
      })
  })
}
export const putAction = (url, body) => {
  return new Promise((resolve, eject) => {
    HttpClient.put(url, body)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        console.log(error.response)
        resolve(error.response)
      })
  })
}
export const putEmptyAction = (url) => {
  return new Promise((resolve, eject) => {
    HttpClient.putEmpty(url)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        console.log(error.response)
        resolve(error.response)
      })
  })
}
export const getAction = (url) => {
  return new Promise((resolve, eject) => {
    HttpClient.get(url)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        console.log(error.response)
        resolve(error.response)
      })
  })
}
export const deleteAction = (url) => {
  return new Promise((resolve, eject) => {
    HttpClient.delete(url)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        console.log(error.response)
        resolve(error.response)
      })
  })
}
