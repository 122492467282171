import React from 'react'
import './style/ExternalLogin.css'
import FacebookLogin from 'react-facebook-login'
import { postAction } from '../../services/action/Action'
import { openSnackbar, startSession } from '../../store/actions'
import { withRouter } from 'react-router-dom'
import config from '../../config.json'
import { connect } from 'react-redux'
const Facebook = ({ openSnackbar, startSession, history }) => {
  const responseFacebook = async (response) => {
    if (!response.accessToken) {
      openSnackbar('error_start', true, false)
    } else {
      const usuario = {
        RegisterOption: 'Facebook',
        AccessToken: response.accessToken,
        UserID: response.id,
        ExpiresIn: response.expiresIn,
        ReauthorizeRequiredIn: '',
        Email: response.email,
        FirstName: response.name,
        LastName: '',
        PhotoUrl: response.picture.data.url,
      }
      await postAction(
        '/auth/registerExternalLogin',
        config.domainURL === 'https://utbaulavirtual.com'
          ? { ...usuario, institutionId: 3 }
          : usuario,
      ).then((response) => {
        if (response.status === 200 && response) {
          openSnackbar('login_successful', true, true)
          const { institutions, courses, user, token, father } = response.data
          window.localStorage.setItem('id', user.id)
          window.localStorage.setItem('token_seguridad', token)
          startSession({ ...user, father: father }, courses, institutions, true)
          history.push('/programs')
        } else {
          openSnackbar('save_failed', true, false)
        }
      })
    }
  }
  const fbButton = (
    <span>
      Facebook<i className="fab fa-facebook-f"></i>
    </span>
  )
  return (
    <React.Fragment>
      <br />
      <FacebookLogin
        appId="1026030154520877"
        autoLoad={false}
        fields="name,email,picture"
        callback={responseFacebook}
        cssClass="btnExternal"
        textButton={fbButton}
      />
    </React.Fragment>
  )
}
export default connect(null, {
  openSnackbar,
  startSession,
})(withRouter(Facebook))
