import React from "react";
import "./style/LoadingScreen.css";
import { LogoCapacita, Circle } from "../animation";
const LoadingScreen = () => {
  return (
    <div className="LoadingScreen">
      <div className="LoadingScreen__main">
        <div className="LoadingScreen__logo">
          <LogoCapacita />
        </div>
         <div className="LoadingScreen__circle">
          <Circle size="170px" semicircle={true} time="3s" />
        </div>
        <div className="LoadingScreen__circle2">
          <Circle size="190px" semicircle={false} time="2.2s" />
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;
