import { H2, H4 } from '../../components/text'
import { ColoredBoxes } from '../../components/boxes'
import { LoadCircle } from '../../components/animation'
import { useAxiosAuth } from '../../hooks'
import { convertD } from '../../utils/ConvertDate'
import { connect } from 'react-redux'
import { changeInfo, changeLayout } from '../../store/actions'
import { useEffect } from 'react'
const Order = (props) => {
  const {
    match: { params },
    changeInfo,
    changeLayout,
  } = props

  useEffect(() => {
    changeLayout()
    changeInfo(
      <>
        <H2>Nuestros detalles bancarios</H2>
        <H4 fontWeight="700">CAPACITA GLOBAL INTERNACIONAL:</H4>
        <H4 textAlign="center">Banco Unión</H4>
        <H4 textAlign="center">NÚMERO DE CUENTA:</H4>
        <H4 textAlign="center">10000019222970</H4>
        <H4 fontWeight="700">CAPACITA GLOBAL INTERNACIONAL:</H4>
        <H4 textAlign="center">Banco Económico</H4>
        <H4 textAlign="center">NÚMERO DE CUENTA:</H4>
        <H4 textAlign="center">2081518492</H4>
        <H4 fontWeight="700">CAPACITA GLOBAL:</H4>
        <H4 textAlign="center">Banco Fassil</H4>
        <H4 textAlign="center">NÚMERO DE CUENTA:</H4>
        <H4 textAlign="center">40555153</H4>
        <H4 fontWeight="700">CAPACITA GLOBAL INTERNACIONAL:</H4>
        <H4 textAlign="center">Banco FIE</H4>
        <H4 textAlign="center">NÚMERO DE CUENTA:</H4>
        <H4 textAlign="center">40013171401</H4>
      </>,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { loading, response } = useAxiosAuth({
    method: 'GET',
    url: `/order/${params.oid}/userDetailed`,
  })
  return (
    <div>
      <br />
      <H2>Detalles de su orden</H2>
      <H4>
        Su cuenta de usuario ha sido creada, por favor verifique el correo
        electrónico enviado con sus datos de usuario y contraseña.
      </H4>
      <H4>Los pasos para finalizar la inscripción son los siguientes:</H4>
      <H4>
        – Realizar depósito a una de las siguientes cuentas que se muestran en
        la parte inferior
      </H4>
      <H4>
        – Una vez realizada la transferencia enviar el comprobante de pago por
        whatsapp al número 77712429
      </H4>
      <br />
      {loading ? (
        <LoadCircle />
      ) : (
        <ColoredBoxes
          boxes={[
            {
              title: params.oid,
              subTitle: 'Numero de pedido',
              status: 'gray',
            },
            {
              title: convertD(response.creationDate, 'L'),
              subTitle: 'Fecha',
              status: 'gray',
            },
            {
              title: response.email,
              subTitle: 'Correo electrónico',
              status: 'gray',
            },
            {
              title: `${response.totalAmount} ${
                response.currency ? response.currency : 'Bs.'
              }`,
              subTitle: 'Total',
              status: 'gray',
            },
          ]}
        />
      )}
    </div>
  )
}

export default connect(null, {
  changeInfo,
  changeLayout,
})(Order)
