import { useAxios } from '../../../hooks'
import { useState, useEffect } from 'react'
import { H3 } from '../../../components/text'
import { Icon } from '../../../components/buttons'
import { BoxFlex } from '../../../components/boxes'
import { validateArray } from '../../../utils/Validation'
import { LoadCircle } from '../../../components/animation'
import SimpleCard from './SimpleCard'

const ListCourses = (props) => {
  const {
    category,
    openSnackbar,
    shopping,
    reloadShopping,
    url,
    membership,
  } = props
  const [page, setpage] = useState(1)

  const { response, loading, reload, header } = useAxios({
    method: 'GET',
    url: `/category/${category}/programManagerList?PageNumber=${page}${url ? url : ''
      }`,
  })

  useEffect(() => {
    reload()
  }, [category, url, page])

  function generatePage(header) {
    if (header && header.pagination) {
      return JSON.parse(header.pagination)
    }
    return null
  }

  return loading ? (
    <LoadCircle />
  ) : (
    <>
      <Pagination
        page={page}
        setpage={setpage}
        headerInfo={generatePage(header)}
        response={response}
      />
      <br />
      <div className="SubCategories__ListCourses">
        {validateArray(response) ? (
          response.map((l) => {
            const filterShopping = shopping.filter(
              (f) => f.programManagerId === l.id,
            )
            return (
              <SimpleCard
                {...props}
                url="graduates"
                type="course"
                key={l.id}
                course={{
                  ...l,
                  programManagerId: l.id,
                  photoUrl: l.photoUrl
                    ? l.photoUrl
                    : 'https://res.cloudinary.com/dzxmzxvbr/image/upload/v1607986593/diplomados/fotos/06GestionTributaria_hadppb.png',
                }}
                color="#458e7f"
                openSnackbar={openSnackbar}
                shopBoolean
                status={filterShopping.length > 0}
                shopping={shopping}
                reloadShopping={reloadShopping}
                membership={membership?.status === 'completed' ? true : false}
              />
            )
          })
        ) : (
          <H3>No hay cursos</H3>
        )}
      </div>
    </>
  )
}

const Pagination = ({ headerInfo, page, setpage, response }) => {
  return (
    <BoxFlex justify="space-between">
      <H3 margin="0" fontWeight="500">
        Mostrando del {(page - 1) * headerInfo.itemsPerPage + 1} -{' '}
        {(page - 1) * headerInfo.itemsPerPage + response.length} de{' '}
        {headerInfo.totalItems} cursos
      </H3>
      <BoxFlex justify="flex-end">
        <Icon
          handleModal={() => setpage(page - 1)}
          disabled={headerInfo.currentPage === 1 ? true : false}
          ico="chevron-left"
        />
        <Icon
          disabled={headerInfo.totalPages <= page ? true : false}
          handleModal={() => setpage(page + 1)}
          ico="chevron-right"
        />
      </BoxFlex>
    </BoxFlex>
  )
}

export default ListCourses
