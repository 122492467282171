import { useEffect } from 'react'
import { connect } from 'react-redux'
import { FooterComponent } from './Home'
import { changeLayout } from '../../store/actions'
import { VideoPlayer } from '../../components/common'
import { H3 } from '../../components/text'
import { BoxFlex } from '../../components/boxes'
import { Link } from 'react-router-dom'
import './NewHome.css'

const NewHome = (props) => {
  const { changeLayout } = props
  useEffect(() => {
    changeLayout('ContentOnly')
  }, [changeLayout])

  function clickHandler(e, element) {
    e.preventDefault()
    document
      .getElementById(element)
      .scrollIntoView({ block: 'start', behavior: 'smooth' })
  }

  return (
    <div className="">
      <div id="contents" className="NewHome__content">
        <CourseSection
          icon="fas fa-book"
          paddingIcon="20px 30px"
          backgroundImage="https://res.cloudinary.com/pataformacgi/image/upload/v1674760868/assets/CURSOS_CORTOS_kreh1u.png"
          text={
            <>
              CURSOS <br /> <span>CORTOS</span>
            </>
          }
        />
        <CourseSection2
          icon="fas fa-graduation-cap"
          paddingIcon="20px 15px"
          backgroundImage="https://res.cloudinary.com/pataformacgi/image/upload/v1674760871/assets/TECNICO_SUPERIOR_edjwfn.png"
          text={
            <>
              TÉCNICO <br /> <span>SUPERIOR</span>
            </>
          }
        />
      </div>
      <div id="contact" className="NewHome__contactContain">
        <div className="NewHome__contactMap">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3824.6664006696315!2d-68.07812600008762!3d-16.54293100001865!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTbCsDMyJzM0LjYiUyA2OMKwMDQnNDEuMyJX!5e0!3m2!1ses-419!2sbo!4v1674762953455!5m2!1ses-419!2sbo"
            width="100%"
            height="450"
            style={{ border: '0' }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div className="NewHome__contact">
          <H3 color="var(--green)" fontSize="2.5rem" fontWeight="700">
            CONTACTANOS
          </H3>
          <p>
            <strong>Dirección: </strong>
            Edif L&M, Av. Montenegro #905, San Miguel (Lado de Subway)
          </p>
          <BoxFlex>
            <a
              href={`https://wa.me/59170140353?text=Hola%20Capacita%20Global%20`}
              target="_blank"
              rel="noopener noreferrer"
              className="CourseInformation__btn green"
            >
              <i className="fab fa-whatsapp"></i> <span>WhatsApp</span>
            </a>
            <a
              href="https://www.facebook.com/CapcitaGlobal"
              target="_blank"
              rel="noopener noreferrer"
              className="CourseInformation__btn blue"
            >
              <i className="fab fa-facebook-messenger"></i>{' '}
              <span>Messenger</span>
            </a>
          </BoxFlex>
        </div>
      </div>
      <FooterComponent />
    </div>
  )
}

const CourseSection = ({ backgroundImage, icon, paddingIcon, text }) => {
  return (
    <div
      style={{
        backgroundImage: `url("${backgroundImage}")`,
      }}
      className="CourseSection"
    >
      <div className="CourseSection__icon">
        <i style={{ padding: paddingIcon }} className={icon}></i>
      </div>
      <div className="CourseSection__text">{text}</div>
      <Link to="/catalogue/short-courses" className="link">
        Nuestros Cursos
      </Link>
    </div>
  )
}

const CourseSection2 = ({ backgroundImage, icon, paddingIcon, text }) => {
  return (
    <div
      style={{
        backgroundImage: `url("${backgroundImage}")`,
      }}
      className="CourseSection"
    >
      {/* <Link to="/catalogue/advanced-technician"> */}
      <div className="CourseSection__icon">
        <i style={{ padding: paddingIcon }} className={icon}></i>
      </div>
      {/* </Link> */}
      <div className="CourseSection__text">{text}</div>
      <Link to="/tecnicosuperior/contabilidadgeneral" className="link">
        Contaduría General
      </Link>
      <Link to="/tecnicosuperior/comerciointernacional" className="link">
        Comercio Internacional
      </Link>
      <Link to="/catalogue/advanced-technician" className="link-success">
        Reserva tu cupo sin tarjeta!
      </Link>
    </div>
  )
}


export default connect(null, {
  changeLayout,
})(NewHome)
