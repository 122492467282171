import './ShortCourses.css'
import { connect } from 'react-redux'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { changeLayout, openSnackbar } from '../../store/actions'
import { H2, H3 } from '../../components/text'
import { DefaultBtn, RenderButton } from '../../components/buttons'
import { ContactModal, RenderModal } from '../../components/modal'
import { useAxios } from '../../hooks'
import { validateArray } from '../../utils/Validation'
import { FooterComponent } from '../Home/Home'
const ShortCourses = (props) => {
  const { changeLayout, openSnackbar, history } = props
  const [modal, setmodal] = useState(true)
  const { response, loading } = useAxios({
    method: 'GET',
    url: '/programManager/featured',
  })
  const { response: newCoursesResponse, loading: newCoursesLoading } = useAxios({
    method: 'GET',
    url: '/programManager/news',
  })
  const { response: category, loading: loadCategory } = useAxios({
    method: 'GET',
    url: '/category/4/children',
  })
  const { response: teachers, loading: loadTeachers } = useAxios({
    method: 'GET',
    url: '/professor/featured',
  })
  useEffect(() => {
    changeLayout('ContentOnly')
  }, [changeLayout])

  return (
    <div className="ShortCourses">
      <div className="ShortCourses__container">
        {modal && (
          <RenderModal
            onClose={() => setmodal(false)}
            width="500px"
            closeOutside={true}
            background="linear-gradient(135deg,rgba(92, 214, 189, 1) 0%, var(--purple) 100%)"
          >
            <ContactModal
              openSnackbar={openSnackbar}
              onClose={() => setmodal(false)}
            />
          </RenderModal>
        )}
        <div className="ShortCourses__marginTop"></div>
        <RenderButton
          padding="10px"
          margin="0 10px 0"
          fontSize="3rem"
          background="var(--green)"
          onClick={() => history.goBack()}
        >
          <i className="fas fa-home"></i>
        </RenderButton>
        <div className="ShortCourses__header">
          <div className="ShortCourses__Htext">
            <H2 fontSize="3.8rem">¡APRENDE SIN LÍMITES!</H2>
            <H3 fontSize="2rem" fontWeight="600">
              Con más de 80 cursos online
            </H3>
            <div className="ShortCourses__Hbtns">
              <DefaultBtn onClick={() => setmodal(!modal)}>
                Regístrate gratis
              </DefaultBtn>
              <DefaultBtn
                onClick={() => history.push('/catalogue/short-courses/4')}
              >
                Ver cursos
              </DefaultBtn>
            </div>
          </div>

          <CardSquareImage
            carosuel={true}
            image={[
              'https://res.cloudinary.com/pataformacgi/image/upload/h_300,c_scale,q_auto,f_auto/v1655472648/assets/capacita_kyqppe_iqizgk.png',
              'https://res.cloudinary.com/pataformacgi/image/upload/h_300,c_scale,q_auto,f_auto/v1655472657/assets/dfdf_rwatxj_bapwzb.png',
              'https://res.cloudinary.com/pataformacgi/image/upload/h_300,c_scale,q_auto,f_auto/v1655472657/assets/capacita_main_bci8rm_cqrqsm.png',
              'https://res.cloudinary.com/pataformacgi/image/upload/h_300,c_scale,q_auto,f_auto/v1655472654/assets/capacita_main22_h7pbpx_yrcbwe.png',
            ]}
          />
        </div>
        <div style={{ height: '80px' }}></div>
        {!newCoursesLoading && (
          <div className="ShortCourses__courses">
            <TitleSquare>CURSOS NUEVOS</TitleSquare>
            <div className="ShortCourses__list">
              {
                validateArray(newCoursesResponse) &&
                newCoursesResponse.map((course) => (
                  <CardShortCourse key={course.id} course={course} />
                ))}
            </div>
          </div>
        )}
        <div style={{ height: '80px' }}></div>
        {!loading && (
          <div className="ShortCourses__courses">
            <TitleSquare>CURSOS DESTACADOS</TitleSquare>
            <div className="ShortCourses__list">
              {validateArray(response) &&
                response.map((course) => (
                  <CardShortCourse key={course.id} course={course} />
                ))}
            </div>
          </div>
        )}
        <H2
          textAlign="center"
          margin="50px 0 20px"
          color="var(--purple)"
          fontSize="3.8rem"
        >
          ¿Por qué <br /> CAPACITA GLOBAL?
        </H2>
        <div className="ShortCourses__advantages">
          <ShortCourseBenefit
            text="Acceso 24 horas al día, sin horarios y a tu ritmo."
            icon="far fa-clock"
          />
          <ShortCourseBenefit
            text="Certificado digital personalizado por cada curso. "
            icon="fas fa-certificate"
          />
          <ShortCourseBenefit
            text="Acceso ilimitado para ver las lecciones todas las veces que necesites."
            icon="fas fa-infinity"
          />
          <ShortCourseBenefit
            text="Aprende desde la comodidad de tu hogar."
            icon="fas fa-laptop-house"
          />
          <ShortCourseBenefit
            text="Aprende con profesionales especialistas en el área."
            icon="fas fa-chalkboard-teacher"
          />
          <ShortCourseBenefit
            text="Cursos de actualización con alta demanda laboral"
            icon="fas fa-briefcase"
          />
        </div>
        <div style={{ height: '100px' }}></div>
        {!loadCategory && (
          <div className="ShortCourses__category">
            <TitleSquare padding="60px 25px 60px 10px">CATEGORÍAS</TitleSquare>
            <div className="ShortCourses__listCategory">
              {validateArray(category) &&
                category.map((item) => (
                  <Link
                    to={`/catalogue/short-courses/4?category=${item.id}`}
                    className="ShortCourses__cardCategory"
                    key={item.id}
                  >
                    <img
                      src={
                        item.miniatureUrl
                          ? item.miniatureUrl
                          : 'https://res.cloudinary.com/pataformacgi/image/upload/h_80,c_scale,q_auto,f_auto/v1655472639/assets/humanidades_afmfm4_d9xbgs.png'
                      }
                      alt=""
                    />
                    <H3
                      fontWeight="600"
                      textAlign="center"
                      color="var(--gray)"
                      margin="5px 0 0"
                    >
                      {item.name}
                    </H3>
                  </Link>
                ))}
            </div>
          </div>
        )}
        <div style={{ height: '120px' }}></div>
        <div className="ShortCourses__section">
          <CardSquareImage image="https://res.cloudinary.com/pataformacgi/image/upload/h_300,c_scale,q_auto,f_auto/v1655472661/assets/capacita12_ofuvmk_s8j8d0.png" />
          <div className="ShortCourses__Htext">
            <H2 fontSize="3.8rem" color="var(--purple)">
              CAPACITA GLOBAL
            </H2>
            <H3 fontSize="2rem" fontWeight="500">
              Capacita Global Internacional la primera plataforma virtual donde
              los estudiantes puedan acceder a un sistema de capacitación y
              formación, de manera responsable, amigable y sencilla
            </H3>
          </div>
        </div>
        <div style={{ height: '120px' }}></div>
        {!loadTeachers && (
          <div className="ShortCourses__teachers">
            <TitleSquare>NUESTROS DOCENTES</TitleSquare>
            <div className="ShortCourses__teachersList">
              {validateArray(teachers) &&
                teachers.map((teacher) => (
                  <CardTeacherFeatured key={teacher.id} teacher={teacher} />
                ))}
            </div>
          </div>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column-reverse',
            margin: '100px 0',
            gap: '100px',
          }}
        >
          <div className="ShortCourses__section">
            <CardSquareImage image="https://res.cloudinary.com/pataformacgi/image/upload/h_300,c_scale,q_auto,f_auto/v1655472653/assets/young-woman-attending-online-class_ylnkab_gli9ez.jpg" />
            <div className="ShortCourses__Htext">
              <H3 fontSize="2rem" fontWeight="500">
                Se enfoca en la educación por resultados, dirigida a
                especializaciones y conocimientos prácticos. Demostrarás a todos
                que eres un profesional capaz de desafiarse a sí mismo.
              </H3>
            </div>
          </div>
          <Link style={{ textDecoration: 'none' }} to="/payment">
            <div className="ShortCourses__Banner">
              <H3
                margin="0"
                fontSize="5rem"
                color="var(--white)"
                fontWeight="500"
              >
                14.<span style={{ fontSize: '2.5rem' }}>99</span> SUS
              </H3>
              <div className="ShortCourses__Btext">
                <H3
                  margin="0"
                  textAlign="center"
                  fontSize="2rem"
                  color="var(--white)"
                  fontWeight="600"
                >
                  Plan Mensual
                </H3>
                <H3
                  margin="0"
                  textAlign="center"
                  color="var(--white)"
                  fontWeight="500"
                >
                  Adquiere una suscripción en nuestra plataforma
                </H3>
              </div>
            </div>
          </Link>
        </div>
        <FooterComponent />
      </div>
    </div>
  )
}

const CardTeacherFeatured = ({ teacher }) => {
  return (
    <div className="CardTeacherFeatured">
      <img
        className="CardTeacherFeatured__image"
        src={
          teacher.photoUrl
            ? teacher.photoUrl
            : 'https://res.cloudinary.com/dzxmzxvbr/image/upload/v1607986593/diplomados/fotos/06GestionTributaria_hadppb.png'
        }
        alt=""
      />
      <div className="CardTeacherFeatured__text">
        <H3
          margin="0"
          padding="15px 10px"
          color="var(--white)"
          fontWeight="600"
          fontSize="1.8rem"
        >
          {teacher.name} {teacher.lastName}
        </H3>
      </div>
      <div className="CardTeacherFeatured__description">
        <H3
          margin="0"
          padding="15px 10px"
          color="var(--white)"
          fontWeight="700"
          fontSize="2rem"
        >
          {teacher.name} {teacher.lastName}
        </H3>
        <H3
          margin="0"
          padding="15px 10px"
          color="var(--white)"
          fontWeight="500"
        >
          {teacher.profession}
        </H3>
      </div>
    </div>
  )
}

const CardSquareImage = ({ image, carosuel = false }) => {
  return (
    <div className="ShortCourses__Himage">
      <div
        style={{ background: 'var(--purple)', transform: 'rotate(-20deg)' }}
        className="ShortCourses__Hsquare"
      >
        <div
          style={{
            background: '#EE7E34',
            transform: 'rotate(-30deg)',
          }}
          className="ShortCourses__Hsquare"
        >
          <div
            style={{
              background: '#5CD6BD',
              transform: 'rotate(50deg)',
            }}
            className="ShortCourses__Hsquare"
          >
            {carosuel === true ? (
              <div className="ShortCourses__Imagecontainer">
                <ul>
                  {image.map((item) => (
                    <li>
                      <img src={item} alt="" />
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <div className="ShortCourses__Imageonly">
                <img src={image} alt="" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const ShortCourseBenefit = ({ icon, text }) => {
  return (
    <div className="ShortCourseBenefit">
      <i className={icon}></i>
      <H3 margin="0" fontWeight="500">
        {text}
      </H3>
    </div>
  )
}
const CardShortCourse = ({ course }) => {
  return (
    <Link
      style={{ textDecoration: 'none' }}
      to={`/graduates/${course.id}/course`}
    >
      <div className="CardShortCourse">
        <div
          className="CardShortCourse__image"
          style={{
            backgroundImage: `url('${course.photoUrl
              ? course.photoUrl
              : 'https://res.cloudinary.com/dzxmzxvbr/image/upload/v1607986593/diplomados/fotos/06GestionTributaria_hadppb.png'
              }')`,
          }}
        ></div>
        <div className="CardShortCourse__content">
          <H3 margin="0 0 5px">{course.name}</H3>
          <div className="CardShortCourse__teacher">
            {course.photoUrlProf && (
              <div
                style={{ backgroundImage: `url("${course.photoUrlProf}")` }}
                className="CardShortCourse__photo"
              ></div>
            )}
            {course.professor && (
              <H3 margin="0" fontWeight="600">
                {course.professor}
              </H3>
            )}
          </div>
          <br />
          {course.description && <H3 fontWeight="500">{course.description}</H3>}
        </div>
      </div>
    </Link>
  )
}
const TitleSquare = ({ children, padding = '30px 25px 30px 10px' }) => {
  return (
    <div className="TitleSquare">
      <div
        style={{
          background: '#EE7E34',
          borderRight: '15px solid #EE7E34',
        }}
        className="TitleSquare__card"
      >
        <div
          style={{
            background: '#5CD6BD',
            borderRight: '15px solid #5CD6BD',
          }}
          className="TitleSquare__card"
        >
          <div
            style={{
              background: 'var(--purple)',
            }}
            className="TitleSquare__card"
          >
            <H3
              fontSize="2rem"
              fontWeight="600"
              color="var(--white)"
              padding={padding}
            >
              {children}
            </H3>
          </div>
        </div>
      </div>
    </div>
  )
}
export default connect(null, { changeLayout, openSnackbar })(ShortCourses)
