import { NormalButton } from '../../../../../components/buttons'
import { CompModal } from '../../../../../components/modal'
const QuizLesson = (props) => {
  const {
    modal,
    dataLock,
    convertD,
    handelQuiz,
    handelModal,
    contentData,
  } = props
  return (
    <>
      {modal ? (
        <CompModal onClose={() => handelModal()} closeOutside={true}>
          <div className="ContentQuiz__modal">
            {!dataLock(contentData.finishDatePerUser) ||
            !contentData.unlimited ? (
              <>
                <h2>Excelente, validarás todo lo aprendido</h2>
                <h2>{contentData.name}</h2>
                <ul>
                  <li>
                    {contentData?.attemps === '1' ||
                    contentData.attemps === undefined ? (
                      <>
                        Puedes presentar el examen <strong>sólo una vez</strong>
                        .
                      </>
                    ) : (
                      <>
                        Puedes presentar el examen{' '}
                        <strong>{contentData.attemps}</strong> veces.
                      </>
                    )}
                  </li>
                  <li>
                    Antes de dar el examen <strong>debes ver los videos</strong>
                    .
                  </li>
                  <li>
                    Tienes <strong>{contentData.timeLimit} minutos</strong> para
                    responder.
                  </li>
                  <li>
                    Para aprobar necesitas una{' '}
                    <strong>nota del {contentData.minimumGrade}%</strong>
                  </li>
                </ul>
                <div className="ContentQuiz__btns">
                  <NormalButton
                    onClick={() => handelModal(contentData.id)}
                    background={true}
                  >
                    Regresar más tarde
                  </NormalButton>
                  <NormalButton onClick={() => handelQuiz(contentData.id)}>
                    Presentar examen
                  </NormalButton>
                </div>
              </>
            ) : (
              <>
                <h2 style={{ color: 'var(--orange2)' }}>{contentData.name}</h2>
                <h2>
                  Fecha Limite:{' '}
                  {convertD(contentData.finishDatePerUser, 'LLLL')}
                </h2>
                <h2>El tiempo para responder su examen ha expirado</h2>
                <div className="ContentQuiz__btns">
                  <NormalButton
                    onClick={() => handelModal(contentData.id)}
                    background={true}
                  >
                    Cerrar
                  </NormalButton>
                </div>
              </>
            )}
          </div>
        </CompModal>
      ) : null}
    </>
  )
}

export default QuizLesson
