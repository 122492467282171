import { Switch, Route, withRouter } from 'react-router-dom'
import React, { useEffect } from 'react'
import {
  Account,
  Chat,
  Home,
  Questions,
  Courses,
  Auth,
  CourseInfo,
  Verify,
  Modules,
  Programs,
  VideoContent,
  HomeworkContent,
  QuizContent,
  Institutions,
  Institution,
  Enrollment,
  Page404,
  AnswersContent,
  ProgramInstitution,
  Catalogue,
  EnglishCourse,
  CoursesTeacher,
  ModulesRolUser,
  QuizEdit,
  QuizRatings,
  CourseInstitution,
  CourseNotes,
  CourseProgress,
  ProgrammingCourse,
  Score,
  ProgrammingInfo,
  EnglishInfo,
  InfoUAGRM,
  Forum,
  Academic,
  Shopping,
  SeminarUAGRM,
  Certificate,
  ListCertificate,
  Order,
  Redirect,
  Confirmation,
  OrderResult,
  Contacts,
  Manager,
  ChessCourse,
  ChessCourseInfo,
  Geolocation,
  ResumeContact,
  Payment,
  PaymentResult,
  JitsiContent,
  ShortCourses,
  Admin,
  CourseNotFound,
  OnlyPayment,
  OnlyPaymentResult,
  UserTracking,
  NewHome,
  Webinar,
  Kids,
  Registered,
  Contents,
  AboutUs,
  GeneralAccounting,
  InternationalTrade,
} from '../view'
import { ProtectedRoute, GoBackFlex } from '../components/navigation'
import TagManager from 'react-gtm-module'
import { changeHeader } from '../store/actions'
import { connect } from 'react-redux'
import ScheduleView from '../containers/institutions/institution/ScheduleView'
import Affiliate from '../containers/affiliate/Affiliate'
import AdvancedTechnician from '../view/Catalogue/AdvancedTechnician'

const Main = (props) => {
  const { changeHeader, history } = props
  const p = history.location.pathname
  useEffect(() => {
    if (p.includes('big-data')) {
      changeHeader(
        <img
          src="https://res.cloudinary.com/pataformacgi/image/upload/h_60,c_scale,q_auto,f_auto/v1655472644/assets/Rectangle_339_y2ol2t_em6bju.png"
          alt="Logotipo UAGRM"
        />,
      )
    } else {
      changeHeader(
        <img
          src="https://res.cloudinary.com/pataformacgi/image/upload/h_60,c_scale,q_auto,f_auto/v1655472641/assets/logoutbglow_tydvkf_ouhkgc.png"
          alt="Logotipo de la UTB"
        />,
      )
    }
    if (p === '/') {
      history.push('/catalogue/short-courses/4')
    }
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        path: p,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [p])
  return (
    <>
      <GoBackFlex location={props.history.location} />

      <Switch>
        {/* <Route exact path="/" component={HomeHero} /> */}
        {/* <Route exact path="/" component={<></>} /> */}
        {/* <Route exact path="/geolocation" component={Geolocation} /> */}
        <Route exact path="/catalogue/short-courses" component={ShortCourses} />
        <Route exact path="/about" component={Home} />
        <Route exact path="/contents" component={Contents} />
        <Route exact path="/redirect" component={Redirect} />
        <Route path="/catalogue" component={Catalogue} />
        <Route path="/certificate/:luid/list" component={ListCertificate} />
        <Route path="/certificate/:luid" component={Certificate} />
        <Route path="/order/:oid" component={Order} />
        <Route path="/confirmation" component={Confirmation} />
        <Route path="/order-result" component={OrderResult} />
        <Route path="/payment-result" component={PaymentResult} />
        <Route path="/payment" component={Payment} />
        <Route path="/kids" component={Kids} />
        <Route path="/Tecnicosuperior/contabilidadgeneral" component={GeneralAccounting} />
        <Route path="/Tecnicosuperior/comerciointernacional" component={InternationalTrade} />

        {/* <ProtectedRoute
          exact
          path="/order/:oid"
          component={Order}
          validate={['isAuthenticated']}
        /> */}

        <ProtectedRoute
          exact
          path="/programs/:status?"
          component={Programs}
          validate={['isAuthenticated']}
        />
        <Route exact path="/shopping-cart" component={Shopping} />
        <ProtectedRoute
          exact
          path="/only-payment"
          component={OnlyPayment}
          validate={['isAuthenticated']}
        />
        <ProtectedRoute
          exact
          path="/only-payment-result"
          component={OnlyPaymentResult}
          validate={['isAuthenticated']}
        />
        <ProtectedRoute
          exact
          path="/manager"
          component={Manager}
          validate={['isAuthenticated']}
        />
        <ProtectedRoute
          exact
          path="/courses"
          component={CoursesTeacher}
          validate={['isAuthenticated']}
        />
        <ProtectedRoute
          exact
          path="/courses/:id/user-tracking"
          component={UserTracking}
          validate={['isAuthenticated']}
        />
        <ProtectedRoute
          exact
          path="/institution"
          component={Institutions}
          validate={['isAuthenticated', 'accessInstitution']}
        />
        <ProtectedRoute
          exact
          path="/institution/:iid/:name?"
          component={Institution}
          validate={['isAuthenticated', 'accessInstitution']}
        />
        <ProtectedRoute
          exact
          path="/institution/:iid/program/:pid"
          component={ProgramInstitution}
          validate={['isAuthenticated', 'accessInstitution']}
        />
        <ProtectedRoute
          exact
          path="/institution/:iid/course/:pid"
          component={CourseInstitution}
          validate={['isAuthenticated', 'accessInstitution']}
        />
        <ProtectedRoute
          exact
          path="/account"
          component={Account}
          validate={['isAuthenticated']}
        />
        <Route exact path="/questions" component={Questions} />
        <Route exact path="/verifyemail" component={Verify} />
        {/* <Route exact path="/calendar" component={Calendar} /> */}
        <ProtectedRoute
          exact
          path="/chat"
          component={Chat}
          validate={['isAuthenticated']}
        />
        {/* <Route path="/auth" component={Auth} /> */}
        <ProtectedRoute
          path="/auth"
          component={Auth}
          validate={['notAuthenticated']}
        />
        <Route path="/graduates/:gid/enrollment/:eid?" component={Enrollment} />
        <Route path="/graduates/:id/:type?" component={CourseInfo} />
        <Route path="/UAGRM/big-data" component={InfoUAGRM} />
        {/* <Route path="/seminar/:id" component={Seminar} /> */}
        <Route path="/seminar/:id" component={SeminarUAGRM} />
        <Route exact path="/course-english/:id" component={EnglishInfo} />
        <Route path="/course-english" component={EnglishCourse} />
        <Route
          exact
          path="/course-programming/:id"
          component={ProgrammingInfo}
        />
        <Route path="/course-programming" component={ProgrammingCourse} />
        <Route exact path="/course-chess/:id" component={ChessCourseInfo} />
        <Route path="/course-chess" component={ChessCourse} />
        <ProtectedRoute
          exact
          path="/course-notes/:cid"
          component={CourseNotes}
          validate={['isAuthenticated']}
        />
        <ProtectedRoute
          exact
          path="/courses/:cid/course-progress"
          component={CourseProgress}
          validate={['isAuthenticated']}
        />
        <ProtectedRoute
          exact
          path="/program/:id"
          component={Courses}
          validate={['isAuthenticated']}
        />
        <ProtectedRoute
          exact
          path="/program/:id"
          component={Courses}
          validate={['isAuthenticated']}
        />
        <ProtectedRoute
          exact
          path="/program/:pid/score"
          component={Score}
          validate={['isAuthenticated']}
        />
        <ProtectedRoute
          exact
          path="/program/:pid/academic/:uid/:type"
          component={Academic}
          validate={['isAuthenticated']}
        />
        <ProtectedRoute
          exact
          path="/program/:pid?/course/:cid/module/:mid/:rol"
          component={ModulesRolUser}
          validate={['isAuthenticated']}
        />
        <ProtectedRoute
          exact
          path="/program/:pid?/course/:cid/module/:mid"
          component={Modules}
          validate={['isAuthenticated']}
        />
        <ProtectedRoute
          exact
          path="/program/:pid?/course/:cid/module/:mid/video/:id"
          component={VideoContent}
          validate={['isAuthenticated']}
        />
        <ProtectedRoute
          exact
          path="/program/:pid?/course/:cid/module/:mid/jitsi/:id"
          component={JitsiContent}
          validate={['isAuthenticated']}
        />
        <ProtectedRoute
          exact
          path="/program/:pid?/course/:cid/module/:mid/home-work/:id"
          component={HomeworkContent}
          validate={['isAuthenticated']}
        />
        <ProtectedRoute
          exact
          path="/program/:pid?/course/:cid/module/:mid/forum/:id"
          component={Forum}
          validate={['isAuthenticated']}
        />
        <ProtectedRoute
          exact
          path="/program/:pid?/course/:cid/module/:mid/quiz/:qid/edit"
          component={QuizEdit}
          validate={['isAuthenticated']}
        />
        <ProtectedRoute
          exact
          path="/program/:pid?/course/:cid/module/:mid/quiz/:qid/ratings"
          component={QuizRatings}
          validate={['isAuthenticated']}
        />
        <ProtectedRoute
          exact
          path="/resume-contact"
          component={ResumeContact}
          validate={['isAuthenticated']}
        />
        <ProtectedRoute
          exact
          path="/admin"
          component={Admin}
          validate={['isAuthenticated']}
        />
        <ProtectedRoute
          exact
          path="/affiliate"
          component={Affiliate}
          validate={['isAuthenticated']}
        />
        <ProtectedRoute
          exact
          path="/program/:pid?/course/:cid/module/:mid/quiz/:qid"
          component={QuizContent}
          validate={['isAuthenticated']}
        />
        <ProtectedRoute
          exact
          path="/program/:pid?/course/:cid/module/:mid/answer/:aid"
          component={AnswersContent}
          validate={['isAuthenticated']}
        />
        <ProtectedRoute
          exact
          path="/calendar/viewSchule/"
          component={ScheduleView}
          validate={['isAuthenticated']}
        />
        <Route path="/coursenotfound" component={CourseNotFound} />
        <Route path="*" component={Page404} />
      </Switch>
    </>
  )
}
export default connect(null, { changeHeader })(withRouter(Main))
