import {
  changeInfo,
  changeLayout,
  changeModal,
  openSnackbar,
  startSession,
} from '../../../../store/actions'
import { connect } from 'react-redux'
import { useEffect, useState } from 'react'
import { getAction } from '../../../../services/action/ActionAuthorization'
import { useActionParam, useAxiosAuth } from '../../../../hooks'
import { handlebtn } from '../../../../utils/ContextHelper'
import { ButtonNavigation, ButtonIcon } from '../../../../components/buttons'
import { LoadCircle } from '../../../../components/animation'
import { BoxFlex } from '../../../../components/boxes'
import { validateStatus } from '../../../../utils/Validation'
import {
  editWhatsApp,
  editWhatsAppCourseProgram,
  inputEditCourse,
} from '../../../../utils/FormParameters'
import { capitalizeJson } from '../../../../utils/ProcessData'
import { convertD, isValidFormat } from '../../../../utils/ConvertDate'
import { H2, H3 } from '../../../../components/text'
import {
  InformationProgram,
  UsersProgram,
} from '../../../../containers/institutions/ProgramInstitution'
import {
  ModulesCourse,
  UsersUTB,
  FormSchedule,
} from '../../../../containers/institutions'
import { AddEducatorForm, FormDefault } from '../../../../components/form'
import { UseOnSubmit } from '../../../../hooks/useOnSubmit'
import { CourseTracking } from '../../../../containers/institutions'
import { LinkEnlace } from '../../../../components/buttons/LinkEnlace'

const CourseInstitution = (props) => {
  const {
    changeInfo,
    changeModal,
    openSnackbar,
    match: { params },
    startSession,
    history,
  } = props

  const [btns, setbtns] = useState({
    btn: [
      { id: 1, name: 'Información' },
      { id: 2, name: 'Módulos' },
      { id: 3, name: 'Estudiantes' },
      { id: 4, name: 'Pre registrados' },
      /* { id: 5, name: 'Horarios' }, */
    ],
    activate: {
      id: 1,
    },
  })
  const [editWhatsApp, setEditWhatsApp] = useState(false)
  useEffect(() => {
    changeInfo(<></>)
    changeLayout()
  }, [changeInfo])

  const { dataRequest, statusRequest, reload } = useActionParam({
    action: getAction,
    param: `/course/${params.pid}/detailed`,
  })
  const { dataRequest: teacherCourse, reload: reloadTeachers } = useActionParam(
    {
      action: getAction,
      param: `/course/${params.pid}/teachers`,
    },
  )
  function handleReload() {
    changeInfo(<></>)
    reloadTeachers()
    reload()
  }

  async function handleDownload() {
    changeModal(true, 'Espere por favor...')
    await getAction(
      `/course/${props.match.params.pid}/califications/xlsx`,
    ).then((response) => {
      if (validateStatus(response.status)) {
        changeModal(
          true,
          'Notas del curso',
          <H3 textAlign="center">
            <a
              style={{ color: 'var(--green)' }}
              href={response.data.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              Descargar archivo
            </a>
          </H3>,
          null,
        )
        window.open(response.data.url, '_blank')
      }
    })
  }
  function processData(values) {
    let formData = new FormData()
    for (let key in values) {
      if (values[key]) {
        if (isValidFormat(values[key])) {
          formData.append(key, convertD(values[key]))
        } else {
          formData.append(key, values[key])
        }
      }
    }
    formData.append('UpdateImg', values.ImgFile ? true : false)
    return formData
  }

  const header = [
    { key: 'name', name: 'Nombre' },
    { key: 'lastName', name: 'Apellidos' },
    { key: 'authorName', name: 'Pregistrado Por' },
    { key: 'status', name: 'Estado' },
  ]
  return (
    <div className="ProgramInstitution">
      {dataRequest && (
        <BoxFlex justify="space-between" wrap="nowrap">
          <H2>
            {params.pid} {dataRequest.name}
          </H2>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ButtonIcon
              onClick={() =>
                changeInfo(
                  <FormDefault
                    inputs={inputEditCourse}
                    onCancel={() => changeInfo(<></>)}
                    method="PUT"
                    url={`/course/${params.pid}`}
                    openSnackbar={openSnackbar}
                    title="Editar curso"
                    handleReload={handleReload}
                    data={capitalizeJson(dataRequest)}
                    processData={processData}
                  />,
                )
              }
              content="Editar información"
              icon="edit"
            />
            <ButtonIcon
              onClick={() =>
                changeInfo(
                  <AddEducatorForm
                    openSnackbar={openSnackbar}
                    course={dataRequest}
                    iid={params.iid}
                    cid={params.pid}
                    handleReload={handleReload}
                    changeModal={changeModal}
                  />,
                )
              }
              content="Adicionar docente"
              icon="user-plus"
            />
            <ButtonIcon
              onClick={handleDownload}
              content="Descargar notas del programa"
              icon="download"
            />
            <ButtonIcon
              onClick={() =>
                changeInfo(
                  <FormSchedule
                    startDate={dataRequest.startDate}
                    finishDate={dataRequest.finishDate}
                    {...props}
                    onCancel={() => changeInfo(<></>)}
                  />,
                )
              }
              content="Horario"
              icon="calendar-check"
            />
            {/* FORMULARIO PARA EDITAR ELACES DE WHATSAPP */}
            {dataRequest?.whatsappGroupUrl !== null &&
              dataRequest?.whatsappGroupUrl !== '' &&
              dataRequest?.whatsappGroupUrl !== undefined && (
                <LinkEnlace
                  content={'Enlace de WhatsApp'}
                  href={dataRequest?.whatsappGroupUrl}
                  prefix="fab"
                  icon="whatsapp"
                  fontSize="16"
                />
              )}
            {/* <ButtonIcon
              onClick={() =>
                changeInfo(
                  <FormDefault
                    inputs={editWhatsAppCourseProgram}
                    onCancel={() => changeInfo(<></>)}
                    // method="PUT"
                    // url={`/course/${params.pid}`}
                    // openSnackbar={openSnackbar}
                    title="Editar enlace de whatsApp"
                    // handleReload={handleReload}
                    data={capitalizeJson(dataRequest.data)}
                    // processData={processData}
                  />,
                )
              }
              content="Editar Enlace de WhatsApp"
              icon="whatsapp"
              prefix="fab"
            /> */}
          </div>
        </BoxFlex>
      )}
      <ButtonNavigation
        btns={btns}
        handlebtn={(btn) => handlebtn(btn, changeInfo, setbtns)}
      />
      {validateStatus(statusRequest) && dataRequest ? (
        btns.activate ? (
          <>
            {btns.activate.id === 1 && (
              <InformationProgram
                typeContent="curso"
                program={dataRequest}
                teachers={teacherCourse}
                reload={reload}
              />
            )}
            {btns.activate.id === 2 && (
              <ModulesCourse
                changeInfo={changeInfo}
                changeModal={changeModal}
                openSnackbar={openSnackbar}
                course={dataRequest}
                reload={handleReload}
              />
            )}
            {btns.activate.id === 3 && (
              <UsersProgram
                type="course"
                url={`/course/${dataRequest.id}/students`}
                iid={params.iid}
                changeInfo={changeInfo}
                openSnackbar={openSnackbar}
                changeModal={changeModal}
                pid={dataRequest.id}
                programManager={dataRequest.programManager}
                program={dataRequest}
                startSession={startSession}
                history={history}
              />
            )}
            {btns.activate.id === 4 && (
              <UsersUTB
                param={`/PreRegisterQuiz/${props.match.params.pid}/${dataRequest.name}/list`}
                hideTitle={true}
                header={header}
                title={`Usuarios pregistrados a ${dataRequest.name}`}
              />
            )}
            {/* {btns.activate.id === 5 && (
              <FormSchedule changeModal={changeModal} {...props} />
            )} */}
          </>
        ) : null
      ) : (
        <LoadCircle />
      )}
    </div>
  )
}

export default connect(null, {
  changeInfo,
  changeModal,
  openSnackbar,
  changeLayout,
  startSession,
})(CourseInstitution)
