import { useState } from 'react'
import {
  InputsJsonQuiz,
  InputsJsonHomework,
  InputsJsonVirtual,
  InputsJsonCertificate,
  InputsJsonForum,
} from '../../../../utils/Constant'
import {
  ContentVIdeo,
  ContentHomework,
  ContentQuiz,
  ContentVirtualClass,
  ContentVideoRol,
  ContentVirtualClassRol,
  ContentQuizRol,
  ContentHomeworkRol,
  ContentCertificate,
  ContentCertificateRol,
  ContentVirtualJitsiClass,
  ContentVirtualJitsiClassRol,
  ContentMaterialRol,
  ContentMaterial,
} from '../../../../components/links'
import FilesForLesson from './FilesForLesson'
import { convertDate } from '../../../../utils/ConvertDate'
import { validateStatus } from '../../../../utils/Validation'
import { capitalizeJson } from '../../../../utils/ProcessData'
import { putLesson } from '../../../../services/action/LessonAction'
import {
  VideoPutLesson,
  GlobalForm,
  MaterialPutLesson,
} from '../../../../components/form'
import { putLessonPublic } from '../../../../services/action/LessonAction'
import { deleteAction } from '../../../../services/action/ActionAuthorization'
const ModuleContainer = (props) => {
  const {
    lection,
    rol,
    openSnackbar,
    changeInfo,
    reload,
    changeModal,
    type,
  } = props
  const [overt, setovert] = useState(lection.public)
  const [load, setload] = useState(true)

  const data = {
    contentData: lection,
    overt,
    ChangePublic,
    PutLesson,
    ViewFiles,
    typeCourse: type,
    deleteLesson,
  }

  function ChangePublic() {
    putLessonPublic(lection.id, overt ? 'unpublish' : 'publish').then((res) => {
      if (validateStatus(res.status)) {
        setovert(!overt)
        openSnackbar(
          `Se ${!overt ? 'publico la' : 'anulo la publicación de la'} lección`,
          true,
          true,
        )
      } else {
        openSnackbar(
          `No se pudo ${
            overt ? 'publicar la' : 'anular la publicación de la'
          } lección`,
          true,
          false,
        )
      }
    })
  }
  const handleSubmit = async (values) => {
    try {
      setload(false)
      let formData = new FormData()
      const date = ['StartDate', 'FinishDate']
      for (let i in values) {
        if (date.indexOf(i) <= -1) {
          if (i === 'Description') {
            let aux = lection.description
            if (lection.description !== values[i]) {
              aux = values[i]
                ? JSON.stringify(values[i]).replace(/'/g, '¦')
                : ''
              aux = aux.replace(/"/g, "'")
            }
            formData.append(i, aux)
          }
          formData.append(i, values[i])
        } else {
          formData.append(i, convertDate(values[i]))
        }
      }
      await putLesson(lection.id, formData).then((response) => {
        setload(true)
        if (validateStatus(response.status)) {
          openSnackbar('Se guardaron tus cambios', true, true)
          reload()
        } else {
          openSnackbar('Algo salio mal', true, false)
        }
      })
    } catch (e) {
      setload(true)
      console.log(e)
    }
  }
  function onSubmit(values) {
    changeModal(
      true,
      '¿Estás seguro que quieres editar la lección?',
      '',
      'Editar',
      () => handleSubmit(values),
    )
  }
  function ViewFiles() {
    changeInfo(
      <FilesForLesson
        openSnackbar={openSnackbar}
        changeModal={changeModal}
        changeInfo={changeInfo}
        lid={lection.id}
      />,
    )
  }
  function deleteLesson(id, name) {
    changeModal(
      true,
      '¿Estás seguro que quieres eliminar esta lección?',
      '',
      'Eliminar',
      () => handleDelete(id, name),
    )
  }
  async function handleDelete(id) {
    await deleteAction(`/lesson/${id}/delete`).then((response) => {
      if (validateStatus(response.status)) {
        openSnackbar('Se elimino la lección', true, true)
        reload()
      } else {
        openSnackbar('No se pudo eliminar la lección', true, false)
      }
    })
  }

  function PutLesson() {
    const data = capitalizeJson({
      ...lection,
      AditionalUrlResources: lection.aditionalUrlResources,
      StartDate: lection.startDatePerUser,
      FinishDate: lection.finishDatePerUser,
    })
    switch (lection.lessonType) {
      case 'video':
        changeInfo(
          <VideoPutLesson
            reload={reload}
            lesson={lection}
            changeInfo={changeInfo}
            changeModal={changeModal}
            openSnackbar={openSnackbar}
          />,
        )
        break
      case 'jitsi':
        changeInfo(
          <GlobalForm
            textBtn="Editar clase virtual"
            InputsJson={InputsJsonVirtual}
            title="Editar clase virtual de Jitsi"
            data={data}
            load={load}
            onSubmit={onSubmit}
            onCancel={() => changeInfo(<></>)}
          />,
        )
        break
      case 'materials':
        changeInfo(
          <MaterialPutLesson
            reload={reload}
            lesson={lection}
            changeInfo={changeInfo}
            changeModal={changeModal}
            openSnackbar={openSnackbar}
          />,
        )
        break
      case 'homework':
        changeInfo(
          <GlobalForm
            textBtn="Editar Tarea"
            InputsJson={InputsJsonHomework}
            title="Editar Tarea"
            data={data}
            load={load}
            onSubmit={onSubmit}
            onCancel={() => changeInfo(<></>)}
          />,
        )
        break
      case 'quiz':
        changeInfo(
          <GlobalForm
            textBtn="Editar examen"
            InputsJson={InputsJsonQuiz}
            title="Editar examen"
            /* data={data} */
            data={{
              ...data,
              QuizTimeLimit: data.TimeLimit,
              QuizMinimumGrade: data.MinimumGrade,
            }}
            load={load}
            onSubmit={onSubmit}
            onCancel={() => changeInfo(<></>)}
          />,
        )
        break
      case 'virtualclass':
        changeInfo(
          <GlobalForm
            textBtn="Editar clase virtual"
            InputsJson={InputsJsonVirtual}
            title="Editar clase virtual"
            data={data}
            load={load}
            onSubmit={onSubmit}
            onCancel={() => changeInfo(<></>)}
          />,
        )
        break
      case 'certificate':
        changeInfo(
          <GlobalForm
            textBtn="Editar certificado"
            InputsJson={InputsJsonCertificate}
            title="Editar certificado"
            data={data}
            load={load}
            onSubmit={onSubmit}
            onCancel={() => changeInfo(<></>)}
          />,
        )
        break
      case 'forum':
        changeInfo(
          <GlobalForm
            textBtn="Guardar cambios"
            InputsJson={InputsJsonForum}
            title="Editar foro"
            data={data}
            load={load}
            onSubmit={onSubmit}
            onCancel={() => changeInfo(<></>)}
          />,
        )
        break
      default:
        break
    }
  }

  switch (lection.lessonType) {
    case 'video':
      return rol ? (
        <ContentVideoRol
          {...data}
          openSnackbar={openSnackbar}
          changeModal={changeModal}
        />
      ) : (
        <ContentVIdeo {...data} />
      )
    case 'homework':
      return rol ? (
        <ContentHomeworkRol
          {...data}
          openSnackbar={openSnackbar}
          changeModal={changeModal}
        />
      ) : (
        <ContentHomework {...data} />
      )
    case 'quiz':
      return rol ? (
        <ContentQuizRol
          {...data}
          openSnackbar={openSnackbar}
          changeModal={changeModal}
        />
      ) : (
        <ContentQuiz {...data} />
      )
    case 'virtualclass':
      return rol ? (
        <ContentVirtualClassRol
          {...data}
          openSnackbar={openSnackbar}
          changeModal={changeModal}
        />
      ) : (
        <ContentVirtualClass {...data} />
      )
    case 'jitsi':
      return rol ? (
        <ContentVirtualJitsiClassRol
          {...data}
          openSnackbar={openSnackbar}
          changeModal={changeModal}
        />
      ) : (
        <ContentVirtualJitsiClass {...data} />
      )
    case 'materials':
      return rol ? (
        <ContentMaterialRol
          {...data}
          openSnackbar={openSnackbar}
          changeModal={changeModal}
        />
      ) : (
        <ContentMaterial {...data} />
      )
    case 'certificate':
      return rol ? (
        <ContentCertificateRol
          {...data}
          openSnackbar={openSnackbar}
          changeModal={changeModal}
        />
      ) : (
        <ContentCertificate {...data} reload={reload} />
      )
    case 'forum':
      return rol ? (
        <ContentHomeworkRol
          {...data}
          openSnackbar={openSnackbar}
          changeModal={changeModal}
        />
      ) : (
        <ContentHomework {...data} reload={reload} />
      )
    default:
      return null
  }
}

export default ModuleContainer
