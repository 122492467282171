import "./style/TablePreregistration.css";
import { getAction } from "../../services/action/ActionAuthorization";
import { useActionParam } from "../../hooks";
import { LoadCircle } from "../../components/animation";
import { NormalLink } from "../../components/buttons";
import { H2 } from "../../components/text";
import TableDefault from "../../components/table/TableDefault";
import { changeInfo } from "../../store/actions";
import { connect } from "react-redux";
import { validateStatus } from "../../utils/Validation";
const headerTable = [
  { key: "email", name: "Correo" },
  { key: "name", name: "Nombre" },
  { key: "lastName", name: "Apellidos" },
  { key: "signedProgramId", name: "Programa" },
  { key: "status", name: "Estado" },
];
const TablePreregistration = ({ changeInfo }) => {
  const { dataRequest, statusRequest } = useActionParam({
    action: getAction,
    param: `/users/${window.localStorage.getItem("id")}/preregister`,
  });

  const handleInfo = (us) => {
    if (us) {
      changeInfo(
        <div className="TablePreregistration__info">
          <H2>Información del Pre-registro</H2>
          <h3 className="">
            {us.name} {us.lastName}
          </h3>
          <h3 className="">{us.email}</h3>
          <NormalLink
            to={`/graduates/${us.signedProgramId}/enrollment/${us.id}`}
          >
            Ver formulario
          </NormalLink>
        </div>
      );
    }
  };

  return (
    <div className="TablePreregistration">
      {validateStatus(statusRequest) ? (
        <TableDefault
          handleInfo={handleInfo}
          header={headerTable}
          main={dataRequest}
        />
      ) : (
        <LoadCircle />
      )}
    </div>
  );
};

export default connect(null, { changeInfo })(TablePreregistration);
