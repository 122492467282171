import './style/QuizContent.css'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { changeLayout, openSnackbar } from '../../../../../store/actions'
import { LoadingScreen, Timer } from '../../../../../components/common'
import * as Yup from 'yup'
import { withRouter } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { validateArray, validateStatus } from '../../../../../utils/Validation'
import { processDragMess } from '../../../../../utils/ProcessData'
import { useActionRequest } from '../../../../../hooks'
import { getAction } from '../../../../../services/action/ActionAuthorization'
import { useState } from 'react'
import { CompModal, RenderModal } from '../../../../../components/modal'
import { H3 } from '../../../../../components/text'
import { NormalButton, DefaultBtn } from '../../../../../components/buttons'
import { Loading } from '../../../../../components/animation'
import { QuizControl } from '../../../../../containers/programs'
import { postQuiz } from '../../../../../services/action/AccountCourses'
import { transformTextJson } from '../../../../../utils/ProcessData'
import { dataLock } from '../../../../../utils/ConvertDate'
const QuizContent = (props) => {
  const {
    changeLayout,
    match: { params },
    history,
    openSnackbar,
  } = props
  const { dataRequest, statusRequest } = useActionRequest({
    action: getAction,
    param: `lesson/${params.qid}`,
  })

  const [actions, setactions] = useState({
    load: true,
    modal: false,
    unanswered: 0,
    question: {},
    timeStop: false,
  })
  const [time, settime] = useState(null)

  function showErrorMessage(message) {
    localStorage.removeItem(
      `countdown${window.localStorage.getItem('id')}QuizId${
        dataRequest.id
      }QuizDto${dataRequest.quizToListDto.id}`,
    )
    history.push(
      `/program/${params.pid}/course/${params.cid}/module/${params.qid}`,
    )
    openSnackbar(message, true, false)
  }
  useEffect(() => {
    if (dataRequest && validateStatus(statusRequest)) {
      if (dataRequest.type === 'asynchronous') {
        if (dataRequest.remainingAttemps === 0) {
          showErrorMessage('Usted ya no tiene más intentos')
        }
      } else if (dataRequest.type === 'graduates') {
        if (dataRequest.remainingAttemps === 0) {
          showErrorMessage('Usted ya no tiene más intentos')
        } else if (dataRequest.limited) {
          if (dataLock(dataRequest.finishDate)) {
            showErrorMessage('La fecha de finalización del examen ha expirado')
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataRequest])

  useEffect(() => {
    if (dataRequest && validateStatus(statusRequest)) {
      const Countdown = JSON.parse(
        window.localStorage.getItem(
          `countdown${window.localStorage.getItem('id')}QuizId${
            dataRequest.id
          }QuizDto${dataRequest.quizToListDto.id}`,
        ),
      )
      settime(
        Countdown && Countdown.id === dataRequest.quizToListDto.id
          ? Countdown.time
          : dataRequest.quizToListDto.timeLimit,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataRequest])

  useEffect(() => {
    changeLayout('FullScreen')
  }, [changeLayout])

  function save(res) {
    console.log(res)
    setactions((a) => ({ ...a, load: true, timeStop: true }))
    if (res.status === 200) {
      openSnackbar('Se enviaron tus respuestas', true, true)
      const { cid, mid, pid } = params
      localStorage.removeItem(
        `countdown${window.localStorage.getItem('id')}QuizId${
          dataRequest.id
        }QuizDto${dataRequest.quizToListDto.id}`,
      )

      if (!dataRequest.limited) {
        history.push(
          `/program/${pid}/course/${cid}/module/${mid}/answer/${dataRequest.id}`,
        )
      } else {
        history.push(`/program/${pid}/course/${cid}/module/${mid}`)
      }
    } else {
      openSnackbar('No se enviaron tus respuestas', true, false)
    }
  }
  const onSubmit = async (values) => {
    setactions((a) => ({ ...a, modal: false, load: false }))
    try {
      await postQuiz(
        window.localStorage.getItem('id'),
        params.qid,
        values,
      ).then((response) => {
        save(response)
      })
    } catch (e) {
      setactions((a) => ({ ...a, load: true }))
    }
  }

  const verifyGenerate = ({ saving }) => (
    values,
    formik,
    questionToListDtos,
  ) => {
    let quiz = {
      ExecutionTime: time,
      questionAnswers: [],
    }
    let unanswered = 0
    questionToListDtos.forEach((question) => {
      switch (question.type) {
        case 'singleChoice':
          const f = values[question.id].split('-')
          if (f.length > 1) {
            quiz.questionAnswers.push({
              questionId: question.id,
              answers: f[1],
            })
          } else {
            unanswered++
            quiz.questionAnswers.push({
              questionId: question.id,
              answers: '0',
            })
          }
          break
        case 'multipleChoice':
          let aux = ''
          if (validateArray(values[question.id])) {
            values[question.id].forEach((res) => {
              aux = aux + res.split('-')[1] + ';'
            })
          } else {
            unanswered++
            aux = '00'
          }
          quiz.questionAnswers.push({
            questionId: question.id,
            answers: aux.substring(0, aux.length - 1),
          })
          break
        case 'conceptRelation':
          let auxRelation = ''
          if (validateArray(values[question.id])) {
            values[question.id].forEach((res, index) => {
              auxRelation = auxRelation + (index + 1) + ';' + res.id + '-'
            })
          }
          quiz.questionAnswers.push({
            questionId: question.id,
            answers: auxRelation.substring(0, auxRelation.length - 1),
          })
          break
        default:
          break
      }
    })
    if (saving) {
      onSubmit(quiz)
    } else if (unanswered > 0) {
      setactions((a) => ({
        ...a,
        modal: !a.modal,
        question: quiz,
        unanswered,
      }))
    } else {
      onSubmit(quiz)
    }
  }
  if (dataRequest !== null && validateStatus(statusRequest)) {
    const { quizToListDto } = dataRequest
    let radioOptions = []
    let initialOptions = {}
    let validation = {}

    if (quizToListDto !== null) {
      const { questionToListDtos } = quizToListDto
      questionToListDtos.forEach((q, ix) => {
        validation = {
          ...validation,
          [q.id]: q.type === 'singleChoice' ? Yup.string() : Yup.array(),
        }
        if (q.type === 'conceptRelation') {
          let aux = transformTextJson(q.responses)
          initialOptions = {
            ...initialOptions,
            [q.id]: processDragMess(aux),
          }
        } else {
          initialOptions = {
            ...initialOptions,
            [q.id]: '',
          }
        }
        let op = []
        let aux = q.responses
        let res = transformTextJson(aux)

        res.forEach((a) => {
          op.push({
            key: a.response,
            value: `${q.id}-${a.id}`,
            FileId: a.FileId,
          })
        })
        radioOptions[ix] = op
      })
      const validationSchema = Yup.object(validation)
      return (
        <div className="QuizContent">
          <div className="QuizContent__header">
            <h2>{dataRequest.name}</h2>
            {dataRequest.name !== quizToListDto.name ? (
              <h2>{quizToListDto.name}</h2>
            ) : null}
          </div>
          <div className="QuizContent__form">
            <Formik
              initialValues={initialOptions}
              validationSchema={validationSchema}
              onSubmit={verifyGenerate({ saving: false })}
            >
              {(formik) => {
                return (
                  <Form>
                    {time && !actions.timeStop && (
                      <div className="QuizContent__chronometer">
                        <h3>Cronómetro del examen</h3>
                        <span>
                          <i className="far fa-clock"></i>
                          <Timer
                            onSaving={(event) =>
                              verifyGenerate({ saving: true })(
                                formik.values,
                                formik,
                                questionToListDtos,
                              )
                            }
                            time={time}
                            settime={settime}
                            qidDto={dataRequest.quizToListDto.id}
                            qid={dataRequest.id}
                          />
                        </span>
                      </div>
                    )}
                    {validateArray(questionToListDtos)
                      ? questionToListDtos.map((l, index) => (
                          <div className="QuizContent__question" key={l.id}>
                            <QuizControl
                              label={l.inquiry}
                              name={l.id}
                              data={l}
                              index={index}
                              options={radioOptions[index]}
                              setValues={formik.setValues}
                              initialValues={formik.initialValues[l.id]}
                            />
                          </div>
                        ))
                      : null}
                    {actions.load ? (
                      <NormalButton
                        type="button"
                        onClick={() =>
                          verifyGenerate({ saving: false })(
                            formik.values,
                            formik,
                            questionToListDtos,
                          )
                        }
                        disabled={!formik.isValid}
                      >
                        Enviar Respuestas
                      </NormalButton>
                    ) : (
                      <Loading />
                    )}
                  </Form>
                )
              }}
            </Formik>
          </div>
          {actions.modal && (
            <CompModal
              onClose={() => setactions((a) => ({ ...a, modal: !a.modal }))}
            >
              <div className="QuizContent__modal">
                <h2>Tienes {actions.unanswered} preguntas sin responder</h2>
                <h3>Seguro quieres enviarlas</h3>
                <div className="QuizContent__Mbtns">
                  <NormalButton
                    background={true}
                    onClick={() =>
                      setactions((a) => ({ ...a, modal: !a.modal }))
                    }
                  >
                    Cancelar
                  </NormalButton>
                  <NormalButton onClick={() => onSubmit(actions.question)}>
                    Enviar
                  </NormalButton>
                </div>
              </div>
            </CompModal>
          )}
        </div>
      )
    } else {
      return <h2>Este cuestionario no tiene preguntas</h2>
    }
  } else if (dataRequest === 'pending_videos_to_see') {
    const { cid, mid, pid } = params
    return (
      <PendingVideos
        onClose={() =>
          history.push(
            `/program/${pid ? pid + '/' : ''}course/${cid}/module/${mid}`,
          )
        }
      />
    )
  } else {
    return <LoadingScreen />
  }
}

const PendingVideos = (props) => {
  const { onClose } = props
  return (
    <RenderModal onClose={onClose} width="500px" closeOutside>
      <H3
        fontWeight="600"
        textAlign="center"
        color="var(--black)"
        fontSize="2rem"
      >
        Te faltan ver videos del curso
      </H3>
      <H3 fontWeight="500" textAlign="center">
        El examen se habilitara una vez terminando de ver los videos restantes
        del curso
      </H3>
      <center>
        <DefaultBtn onClick={onClose}>Volver al curso</DefaultBtn>
      </center>
    </RenderModal>
  )
}
export default connect(null, { changeLayout, openSnackbar })(
  withRouter(QuizContent),
)
