export function isAuthenticated(user) {
  return { bool: user ? user.authenticate : false, route: '/auth/login' }
}
export function accessCourse(user) {
  return { bool: user ? user.authenticate : false, route: '/courses' }
}
export function noAuthenticate(user) {
  return {
    bool: user ? !user.authenticate : true,
    route: '/catalogue/short-courses/4',
  }
}
export function accessInstitution(user) {
  if (user.institutions.length > 0) {
    return { bool: true, route: '/catalogue/short-courses/4' }
  } else {
    return { bool: false, route: '/catalogue/short-courses/4' }
  }
}
