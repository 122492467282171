import React from 'react'
import { H2, H4 } from '../../components/text'
const OnlyPaymentResult = () => {
  return (
    <div>
      <H2 textAlign="center">Su pago se realizo con éxito</H2>
      {/* <H4>
        Para verificar que la transacción se realizo con éxito comuníquese con
        nuestros asesores en ventas
      </H4> */}
      <H4>
        Una vez realizada la transferencia enviar el comprobante de pago por
        whatsapp al número <strong>+591 72072014</strong>
      </H4>
    </div>
  )
}

export default OnlyPaymentResult
