import './Catalogue.css'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { changeLayout } from '../../store/actions'
import { NavCatalogue } from '../../components/links'
import { H2 } from '../../components/text'
import { VideoPlayer } from '../../components/common'
import { LoadCircle } from '../../components/animation'
import RouteCatalogue from '../../routes/RouteCatalogue'
import { useAxios } from '../../hooks'

const Catalogue = ({ changeLayout }) => {
  const { response, loading } = useAxios({
    method: 'GET',
    url: '/category',
  })
  useEffect(() => {
    changeLayout('FullScreen')
  }, [changeLayout])

  return (
    <div className="Catalogue">
      <div className="Catalogue__head">
        <div className="Catalogue__video">
          <H2 fontSize="2.5rem" textTransform="uppercase" textAlign="center">
            Pasos para acceder al cupón de la UTB
          </H2>
          <center>
            <VideoPlayer
              url="https://vimeo.com/727827470"
              width="100%"
              controls={true}
              playing={false}
            />
          </center>
        </div>
        <div className="Catalogue__navigation">
          {/* {loading ? (
            <LoadCircle />
          ) : validateArray(response) ? (
            response
              .map((category, index) =>
                category.url !== 'courses-children' ? (
                  <NavCatalogue
                    key={category.id}
                    color={listColor[index % 3]}
                    url={
                      category.url === 'courses-children'
                        ? 'https://www.capacitakids.com/'
                        : `/catalogue/${category.url}/${category.id}`
                    }
                  >
                    {category.name}
                  </NavCatalogue>
                ) : null,
              )
              .reverse()
          ) : (
            <H3>Contenido no disponible</H3>
          )} */}
          <NavCatalogue color="#e3ad2d" url="/catalogue/graduates">
            <h1>Postgrados</h1>
          </NavCatalogue>
          <NavCatalogue color="#6a3081" url="/catalogue/short-courses/4">
            <h1>Pastillas educativas</h1>
          </NavCatalogue>
          {/* <NavCatalogue url="/catalogue/courses-children">
            CURSOS - NIÑ@S
          </NavCatalogue> */}
          {/* <NavCatalogue color="#d17742" url="/catalogue/seminars">
            Seminarios
          </NavCatalogue> */}
        </div>
      </div>
      <br />
      {loading ? <LoadCircle /> : <RouteCatalogue response={response} />}
      {/* <SimpleCard />
      <SimpleCardWithSimpleImage /> */}
    </div>
  )
}

export default connect(null, { changeLayout })(Catalogue)
