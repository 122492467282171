import React from 'react'
import './style/Modal.css'
import { withRouter } from 'react-router-dom'
const Modal = (props) => {
  const { redirect } = props
  function verifyPath() {
    let pathReturn = '/catalogue/short-courses/4'

    if (redirect) {
      const {
        from: { pathname: path1, state },
      } = redirect
      if (path1 !== '/') {
        const path2 = state !== undefined ? state.from.pathname : ''
        if (path1 !== path2) {
          pathReturn = path1
        }
      }
    }
    return pathReturn
  }
  function onClose() {
    const rdt = redirect
      ? verifyPath(redirect.from.pathname)
      : '/catalogue/short-courses/4'

    props.history.push({
      pathname: rdt ? rdt : '/catalogue/short-courses/4',
      state: {
        from: redirect ? redirect.from : '/catalogue/short-courses/4',
      },
    })
  }

  return (
    <div className="Modal">
      <div className="Modal__container">
        <button onClick={onClose} className="Modal__close-button">
          X
        </button>
        {props.children}
      </div>
    </div>
  )
}

export default withRouter(Modal)
